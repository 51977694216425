<template>
  <section class="grid-view">
    <b-card
        v-for="item in courses"
        :key="item.id"
        class="ecommerce-card"
        no-body
    >
      <div class="item-img text-center">
        <b-link :to="`/moje-kursy/${item.slug}`">
          <b-img
              :alt="`${item.title}-${item.id}`"
              fluid
              class="card-img-top"
              :src="`${item.thumbnail}`"
          />
        </b-link>
      </div>

      <!-- Product Details -->
      <b-card-body>
        <div class="item-wrapper">
          <div>
            <h3>{{ item.title }}</h3>
          </div>
        </div>
        <b-card-text class="item-description">
          {{ item.short_description }}
        </b-card-text>
        <b-card-text class="text-center">
          <h6 class="text-info">Dostęp wygasa: {{ item.expiry_date }}</h6>
        </b-card-text>
      </b-card-body>

      <!-- Product Actions -->
      <div class="item-options text-center">
        <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            :to="`/moje-kursy/${item.slug}`"
        >
          <span>Przejdź do kursu</span>
        </b-button>
      </div>
    </b-card>
  </section>
</template>
<script>
import {BButton, BCard, BCardBody, BCardText, BImg, BLink, BListGroup, BListGroupItem, BTable} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BImg,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'Nazwa kursu',
        },
        {
          key: 'slug',
          label: 'Akcje',
        },
      ],
      courses: [],
    }
  },
  created() {
    this.fetchCourses()
  },
  methods: {
    fetchCourses() {
      this.$http.get(`https://${this.$store.state.api_domain}/api/my/courses`).then(response => {
        console.log('COURSES', response.data)
        this.courses = response.data.courses
      })
    },
  },
}
</script>

<style lang="scss">
.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  &.wishlist-items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .ecommerce-card {
    overflow: hidden;

    // Item Image
    .item-img {
      padding-top: 0.5rem;
      min-height: 15.85rem;
      display: flex;
      align-items: center;
    }

    // Item content wrapper
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    // Hiding list view items
    .shipping,
    .item-company,
    .item-options .item-price {
      display: none;
    }

    .item-options {
      display: flex;
      flex-wrap: wrap;

      .btn-cart,
      .btn-wishlist {
        flex-grow: 1;
        border-radius: 0;
      }
    }

    .item-name {
      margin-top: 0.75rem;
    }

    // Item Description
    .item-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 0.2rem;
    }

    .card-body {
      padding: 1rem;
    }
  }
}
@media all and (max-width: 768px){
  .grid-view {
    grid-template-columns: 1fr;
  }
}
</style>

